<template>
  <div>
    <title>Education Sector Page</title>
    <UserPageContent
        :content="userTypeContentEducationSector"
    />
  </div>
</template>

<script>
import UserPageContent from "@/components/UserPageContent";
import {mapGetters} from "vuex";

export default {
  name: "EducationSector",
  metaInfo: {
    title: 'LearningPlus for Education Sector',
    meta:[
      { description: 'LearningPlus was created with a clear vision and purpose - to provide high-quality support services to the FE sector, organisations and individuals, developing a flexible way to study in order to improve job prospects and enhance career opportunities. From developing high-quality learning resources to dedicated Account managers and Learner Advisors guiding learners through their journey - it\'s all about the learner! ' },
      { image: 'https://www.learningplus.co.uk/images/customer/education.jpg' },
    ],
  },
  components: {UserPageContent},
  computed: {
    ...mapGetters(['userTypeContentEducationSector']),
  },
};
</script>
